import React from 'react'

const Loyalty_program = () => {
  return (

    <>
  <section>
      <div className="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:gap-32 lg:grid-cols-2 items-center">
        <div className="relative overflow-hidden lg:order-last order-last flex flex-col rounded-3xl drop-shadow-[0_10px_20px_rgba(236,57,42,0.25)]">
            <img
              alt="Wallet Digital"
              src="https://read.cardtonic.com/wp-content/uploads/2024/08/Giftcards-100.jpg"
              className="object-cover w-full h-[520px] rounded-3xl"
            />
          </div>
          <div className="lg:py-28 text-left">
            <h1 className="text-7xl font-extrabold sm:text-9xl text-black">
              <span className="block">Lealtad</span>
              <span
                className="mt-4 w-full ml-auto px-12 py-4 rounded-r-full text-white lg:text-7xl text-5xl font-bold flex items-center justify-end"
                style={{ background: "linear-gradient(to right, white, #f8857c, #fa5d5b)" }}
              >
                Fideliza Hoy 
              </span>
            </h1>
            <p className="mt-8 text-gray-500 text-2xl w-full tracking-normal leading-relaxed">
            Descubre cómo nuestra solución de fidelización puede transformar la relación con tus clientes y aumentar sus interacciones con tu marca y generar mas ventas.
            </p>
            <div className="mt-8">
            <a
            class="inline-block rounded-full border border-[#FE7066] bg-[#FE7066] px-16 py-4 text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden"
            href="#"
            >
                Comienza Hoy
            </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="px-4 py-16 mx-auto  md:max-w-full lg:max-w-screen-xl lg:px-8 lg:py-10">
    <div className="p-8 rounded-3xl sm:p-12 bg-[#F1F1F1]">
        <h2 className="text-4xl sm:text-5xl font-extrabold text-[#1d1d1f]">
        Crea tu programa de fidelidad y empieza a aplicarlo en cuestión de minutos.
        </h2>
        <div className="flex flex-col items-center justify-between lg:flex-row mt-8 rounded-3xl bg-[#F1F1F1] lg:gap-12">
    {/* Lado izquierdo - Imagen del combo */}
    <div className="relative lg:w-1/2 mb-8 lg:mb-0 ">
      <img
        className="lg:w-full lg:h-96 rounded-2xl shadow-lg object-cover object-top"
        src="https://img.invupos.com/clauweb/Wallet%20Digital/Combodeldia.webp"
        alt="Combo del día"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/75 to-transparent rounded-2xl flex items-end justify-center p-4">
        <p className="text-white text-2xl font-semibold">Promociones únicas</p>
      </div>
    </div>

    {/* Lado derecho - Texto y lista */}
    <div className="lg:max-w-xl lg:pl-10 p-8">
      <ul className="space-y-10 text-gray-700 text-2xl list-disc">
        <li>
          <span className="font-bold ">Fácil configuración:</span> <br/> Completa un formulario inicial para personalizar cada detalle.
        </li>
        <li>
          <span className="font-bold">Acumulación y canje de puntos:</span> <br/> Define cómo tus clientes ganarán y usarán puntos.
        </li>
        <li>
          <span className="font-bold">Niveles exclusivos:</span> <br/> Diseña niveles de lealtad con beneficios únicos.
        </li>
      </ul>
    </div>
  </div>
    </div>
    </div>


   
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl  lg:px-8 lg:py-20">
    <h2 className="text-5xl sm:text-6xl font-extrabold text-[#1d1d1f] py-10 mb-8 md:mb-0 text-left md:text-center lg:text-left md:mb-6 lg:mb-0">
  Beneficia tus clientes con <span className="hidden lg:inline"><br/></span>
  <span className="text-[#FE7066]">Puntos</span>
</h2>
  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-5">
      {/* Tarjeta de puntos */}
      <div className=" lg:px-8 relative md:col-span-2 lg:col-span-2 items-center flex  justify-center md:justify-center">
      <div className="max-w-xl relative w-full h-[260px] lg:ml-0 ml-10">
        {/* Efecto de capas */}
        <div className="absolute top-[-40px] left-[-40px] w-full h-full bg-[#FFE9E9] rounded-[2rem] z-0"></div>
        <div className="absolute top-[-20px] left-[-20px] w-full h-full bg-[#F58F87] rounded-[2rem] z-0"></div>

        {/* Tarjeta principal */}
        <div className="relative bg-[#FE7066] text-white rounded-[2rem] p-6 w-full h-full z-10 shadow-md">
          <div className="flex justify-between items-start">
            <h2 className="text-4xl font-bold leading-tight">
              Acumula puntos
            </h2>
            <div className="bg-white text-rose-500 rounded-full p-2">
              <svg className="w-8 h-8" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 7l-10 10M17 17V7H7" />
              </svg>
            </div>
          </div>
          <p className="mt-4 text-white/90 leading-relaxed text-2xl">
          Premia a tus clientes con puntos canjeables para sus próximas compras.
          </p>
        </div>
      </div>
    </div>
    {/* Imagen ahora ocupa 4 columnas en desktop */}
    <div className="grid gap-6 md:col-span-2 lg:col-span-3">
      <div>
        <img
          className="w-full h-auto"
          src="https://img.invupos.com/clauweb/Loyalty/Post%20para%20Instagram%20recordatorio%20frase%20no%20olvidar%20amarillo%20naranja%201.png"
          alt="Promoción"
        />
      </div>
      <div className="mt-8 text-center">
            <a
            class="inline-block rounded-full border border-[#FE7066] bg-[#FE7066] px-16 py-4 text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden"
            href="#"
            >
                Comienza Hoy
            </a>
            </div>
    </div>
  </div>
</div>

<div className="px-4 mx-auto md:max-w-full lg:max-w-screen-xl lg:px-8">
  <div className="rounded-3xl sm:p-6 bg-[#F1F1F1]">
    <div className="flex flex-col lg:flex-row px-4 py-5">
      {/* Tarjetas - 50% del espacio */}
      <div className="lg:w-1/2 content-center">
        <div className="grid grid-cols-3 gap-4 text-center">
          {/* Bronce */}
          <div className="border-2 border-[#2C2D84] rounded-xl px-4 py-6 bg-white">
            <h3 className="text-lg font-semibold text-gray-800">Bronce</h3>
            <p className="text-3xl font-extrabold text-[#2C2D84] mt-2">x1</p>
            <p className="text-sm font-medium mt-2 text-black">Puntos sencillos</p>
          </div>
          {/* Plata */}
          <div className="border-2 border-[#E63E2D] rounded-xl px-4 py-6 bg-white">
            <h3 className="text-lg font-semibold text-gray-800">Plata</h3>
            <p className="text-3xl font-extrabold text-[#E63E2D] mt-2">x3</p>
            <p className="text-sm font-medium mt-2 text-black">Puntos Triples</p>
          </div>
          {/* Oro */}
          <div className="border-2 border-[#F2A343] rounded-xl px-4 py-6 bg-white">
            <h3 className="text-lg font-semibold text-gray-800">Oro</h3>
            <p className="text-3xl font-extrabold text-[#F2A343] mt-2">x5</p>
            <p className="text-sm font-medium mt-2 text-black">Quintuplica puntos</p>
          </div>
        </div>
      </div>

      {/* Texto - 50% del espacio */}
      <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-10 content-start lg:px-12 lg:mt-0 mt-6">
        <div className="text-gray-500 text-xl space-y-3 md:col-span-1 tracking-normal leading-relaxed">
          <h2 className="text-4xl sm:text-4xl font-extrabold text-[#1d1d1f]">
            Personaliza los niveles
          </h2>
          <p>
            Define cómo se acumulan y canjean los puntos por beneficios. Establece la duración de los puntos para incentivar su uso.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>



<div className="px-4 py-16 mx-auto md:max-w-full lg:max-w-screen-xl lg:px-8 mt-12">
  <div className="text-left max-w-[800px]">
      <h2 className="text-5xl sm:text-6xl font-extrabold text-[#1d1d1f] py-10 md:mb-0 text-left md:text-left lg:text-left md:mb-6 lg:mb-0">
      Conecta mejor con tus clientes gracias a los datos.
    </h2>
  </div>

  {/* Contenido principal */}
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
    {/* Tarjeta izquierda */}
    <div className="bg-[#F8F8F8] rounded-2xl p-10 space-y-8">
      <h3 className="text-5xl font-bold text-[#FF9730]">Promociones y automatización</h3>
      <p className="text-gray-500 text-2xl max-w-lg tracking-normal leading-relaxed ">
        Define regalos y condiciones especiales; automatiza notificaciones, promociones, y doble puntaje en productos específicos.
      </p>

      <div class="w-full max-w-xl h-[75px] flex overflow-hidden bg-[#EAEAEA] rounded-3xl">
        <div class="progress-stripes h-full"></div>
        <div class="w-[20%] h-full flex items-center justify-center text-[#f7901e] font-extrabold text-2xl pulse-percentage">
          80%
        </div>
      </div>
      </div>

    {/* Tarjeta derecha - gráfica (placeholder) */}
    <div class="bg-[#F8F8F8] rounded-3xl p-6 w-full max-w-4xl mx-auto">
  <div class="flex justify-between items-start mb-6">
  <div class="flex items-center bg-white px-6 py-3 rounded-full shadow-sm w-fit">
  <div class="w-14 h-14 flex items-center justify-center rounded-full bg-[#EB7162] text-[#fff]">
  <svg  xmlns="http://www.w3.org/2000/svg"  width="30"  height="30"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chart-bar"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 13a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M15 9a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M9 5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M4 20h14" /></svg>
  </div>
  <span class="ml-4 text-xl font-semibold text-[#1d1d1f]">Comparacion de ventas</span>
</div>
    <div class="flex flex-col gap-2 text-sm">
      <button class="text-[#1F2331] bg-[#EAEAEA] rounded-full px-2 py-1">12h</button>
      <div className='text-right'>
      <button class="text-white bg-[#FF6551] rounded-full px-3 py-1 font-medium">24h</button>
      </div>
      <button class="text-[#1F2331] bg-[#EAEAEA] rounded-full px-2 py-1">A week</button>
      <button class="text-[#1F2331] bg-[#EAEAEA] rounded-full px-2 py-1">A month</button>
    </div>
  </div>

  <div class="relative h-60 border-l border-gray-200 pl-4">
    <div class="absolute top-0 w-full border-t border-gray-200"></div>
    <div class="absolute top-1/4 w-full border-t border-gray-200"></div>
    <div class="absolute top-1/2 w-full border-t border-gray-200"></div>
    <div class="absolute top-3/4 w-full border-t border-gray-200"></div>

    <div class="absolute left-0 top-0 -translate-y-1/2 text-sm text-gray-400">2500</div>
    <div class="absolute left-0 top-1/4 -translate-y-1/2 text-sm text-gray-400">1500</div>
    <div class="absolute left-0 top-1/2 -translate-y-1/2 text-sm text-gray-400">1000</div>
    <div class="absolute left-0 top-3/4 -translate-y-1/2 text-sm text-gray-400">500</div>
    <div class="absolute left-0 bottom-0 translate-y-full text-sm text-gray-400">0</div>

    <div class="flex items-end justify-between h-full w-full pr-10 pl-6">
      <div class="bar w-5 h-[40%]"></div>
      <div class="bar w-5 h-[65%]"></div>
      <div class="bar w-5 h-[55%]"></div>
      <div class="bar w-5 h-[60%]"></div>
      <div class="bar w-5 h-[55%]"></div>
      <div class="bar w-5 h-[90%] active"></div>
      <div class="bar w-5 h-[45%]"></div>
      <div class="bar w-5 h-[70%]"></div>
      <div class="bar w-5 h-[72%]"></div>
      <div class="bar w-5 h-[75%]"></div>
    </div>
  </div>
</div>

  </div>

  {/* Tarjetas informativas pequeñas */}
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12 text-center">
    <div className="bg-[#F8F8F8] rounded-2xl p-8">
      <h4 className="text-[#FF9730] font-bold mb-2 text-2xl">Campañas de email marketing</h4>
      <p className="text-gray-600 text-lg mt-3">Crea promociones y mensajes personalizados.</p>
    </div>
    <div className="bg-[#F8F8F8] rounded-2xl p-8">
      <h4 className="text-[#FF9730] font-bold mb-2 text-2xl">Monitorea tus <br/> promociones</h4>
      <p className="text-gray-600 text-lg mt-3">Clientes fidelizados, ventas del programa de lealtad y el gasto promedio comparado con otros clientes.</p>
    </div>
    <div className="bg-[#F8F8F8] rounded-2xl p-8">
        <h4 className="text-[#FF9730] font-bold mb-2 text-2xl break-words max-w-[220px] mx-auto">
        Bases de datos de clientes
      </h4>
      <p className="text-gray-600 text-lg mt-3">Segmenta a tus clientes con etiquetas para campañas más efectivas</p>
    </div>
  </div>
</div>

    </>
  )
}

export default Loyalty_program