import "./App.css";
import Landing from "./pages/Landing";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Loyalty from "./pages/Loyalty";
import FoodOrder from "./pages/FoodOrder";
import Ecommerce from "./pages/Ecommerce";
import Footer from "./components/Footer";

import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { withRouter } from "react-router-dom";
import Restaurants from "./pages/Restaurants";
import Retail from "./pages/Retail";
import Partnership from "./pages/Partnership";
import QR from "./pages/QR";
import Kiosks from "./pages/Kiosks";
import Blog from "./pages/Blog";
import Wallet_digital from "./pages/Wallet_digital";
import Loyalty_program from "./pages/Loyalty_program";
import Productos_NFC from "./pages/Productos_NFC";
import Unlimited_integrations from "./pages/Unlimited_integrations";

const baseRouteUrl = "/:locale(es|en)?";
export const baseUrl = i18n.language === "en" ? "" : "/" + i18n.language;

function App() {
  const { i18n } = useTranslation();

  const lang = i18n.language;
  console.log(lang);
  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route exact path={baseRouteUrl + "/"}>
          <Landing />
        </Route>
        
        {/* start new */}
        <Route exact path={baseRouteUrl + "/wallet-digital"}>
          <Wallet_digital/>
        </Route>

        <Route exact path={baseRouteUrl + "/loyalty-program"}>
          <Loyalty_program/>
        </Route>

        <Route exact path={baseRouteUrl + "/productos-nfc"}>
          <Productos_NFC/>
        </Route>
        
        <Route exact path={baseRouteUrl + "/integraciones-sin-limites"}>
          <Unlimited_integrations/>
        </Route>
        {/* end new */}

        <Route exact path={baseRouteUrl + "/food-order"}>
          <FoodOrder />
        </Route>

        <Route exact path={baseRouteUrl + "/restaurants"}>
          <Restaurants />
        </Route>
        <Route exact path={baseRouteUrl + "/retail"}>
          <Retail />
        </Route>
        <Route exact path={baseRouteUrl + "/ecommerce"}>
          <Ecommerce />
        </Route>
        <Route exact path={baseRouteUrl + "/loyalty"}>
          <Loyalty />
        </Route>
        <Route exact path={baseRouteUrl + "/qr"}>
          <QR />
        </Route>
        <Route exact path={baseRouteUrl + "/kiosks"}>
          <Kiosks />
        </Route>
        <Route exact path={baseRouteUrl + "/partnership"}>
          <Partnership />
        </Route>

        {/* Blog */}
        <Route exact path={baseRouteUrl + "/blog"}>
          <Blog />
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default withRouter(App);
