import React from 'react'

const Unlimited_integrations = () => {
  return (
    <>
    <section>
      <div className="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:gap-32 lg:grid-cols-2 items-center">
        <div className="relative overflow-hidden lg:order-last order-last flex flex-col rounded-3xl drop-shadow-[0_10px_20px_rgba(236,57,42,0.25)]">
            <img
              alt="Wallet Digital"
              src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/Integraciones-hero.webp"
              className="object-cover w-full h-full rounded-3xl"
            />
          </div>
          <div className="lg:py-28 text-left">
            <h1 className="text-6xl font-extrabold sm:text-8xl text-black">
              <span className="block">Integraciones</span>
              <span
                className="mt-4 w-full ml-auto px-12 py-4 rounded-r-full text-white lg:text-7xl text-5xl font-bold flex items-center justify-end"
                style={{ background: "linear-gradient(to right, white, #f8857c, #fa5d5b)" }}
              >
                Sin limites
              </span>
            </h1>
            <p className="mt-8 text-gray-500 text-2xl w-full tracking-normal leading-relaxed">
            Descubre cómo nuestros productos NFC pueden transformar la experiencia de tus clientes y optimizar tus operaciones. Desde pagos rápidos y seguros hasta integraciones inteligentes, llevamos la innovación a la palma de tu mano.
            </p>
            <div className="mt-8">
            <a
            class="inline-block rounded-full border border-[#FE7066] bg-[#FE7066] px-16 py-4 text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden"
            href="#"
            >
                Comienza Hoy
            </a>
            </div>
          </div>
        </div>
      </div>
    </section>


    <div className="px-4 py-16 mx-auto  md:max-w-full lg:max-w-screen-xl lg:px-8 lg:py-10">
    <div className="p-8 rounded-3xl sm:p-12 bg-[#F1F1F1]">
        <h2 className="text-4xl sm:text-5xl font-extrabold text-[#1d1d1f]">
        Integraciones con sistema POS
        </h2>
        <div className="flex flex-col items-center justify-between lg:flex-row mt-8 rounded-3xl bg-[#F1F1F1] lg:gap-12">
    {/* Lado izquierdo - Imagen del combo */}
        <div className="relative lg:w-1/2 mb-8 lg:mb-0 ">
        <img
            className="lg:w-full lg:h-[420px] rounded-2xl shadow-lg object-cover object-top"
            src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/API-Abierta.webp"
            alt="Combo del día"
        />
      <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent rounded-2xl flex items-end justify-center p-4">
        <p className="text-white text-2xl font-semibold">API Abierta</p>
      </div>
    </div>

    {/* Lado derecho - Texto y lista */}
    <div className="lg:max-w-xl lg:pl-10 p-8">
      <ul className="space-y-4 text-gray-700 text-xl list-disc">
        <li>
          <span className="font-bold ">En CLAU.io conectamos tu negocio con los mejores sistemas POS del mercado. Simplifica tus operaciones, automatiza procesos y gestiona ventas en tiempo real gracias a nuestras integraciones sin complicaciones.</span>
        </li>
        <li>
          <span className="font-bold">Integración sin límites: Compatible con POS líderes para adaptarse a tu modelo de negocio.</span> 
        </li>
        <li>
          <span className="font-bold">Procesos automatizados: Sincroniza inventarios, ventas y reportes automáticamente.</span>
        </li>
        <li>
          <span className="font-bold">Datos en tiempo real: Accede a información clave para tomar decisiones inteligentes.</span>
        </li>
        <li>
          <span className="font-bold">Fácil de implementar: Sin interrupciones para tu negocio.</span>
        </li>
      </ul>
    </div>
  </div>
    </div>
    </div>


       
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl  lg:px-8 lg:py-20">
    <h2 className="text-5xl sm:text-6xl font-extrabold text-[#1d1d1f] py-10 mb-8 md:mb-0 text-left md:text-center lg:text-left md:mb-6 lg:mb-0">
    Un ecosistema <span className="hidden lg:inline"></span>
  <span className="text-[#FE7066]">conectado</span>
</h2>
  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-5">
      {/* Tarjeta de puntos */}
      <div className=" lg:px-8 relative md:col-span-2 lg:col-span-2 items-center flex  justify-center md:justify-center">
      <div className="max-w-xl relative w-full h-[385px] lg:ml-0 ml-10">
        {/* Efecto de capas */}
        <div className="absolute top-[-40px] left-[-40px] w-full h-full bg-[#FFE9E9] rounded-[2rem] z-0"></div>
        <div className="absolute top-[-20px] left-[-20px] w-full h-full bg-[#F58F87] rounded-[2rem] z-0"></div>

        {/* Tarjeta principal */}
        <div className="relative bg-[#FE7066] text-white rounded-[2rem] p-6 w-full h-full z-10 shadow-md">
          <div className="flex justify-between items-start">
            <h2 className="text-4xl font-bold leading-tight">
            Todo en un lugar
            </h2>
            <div className="bg-white text-rose-500 rounded-full p-2">
              <svg className="w-8 h-8" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 7l-10 10M17 17V7H7" />
              </svg>
            </div>
          </div>
          <p className="mt-4 text-white/90 leading-relaxed text-2xl">
          Con CLAU y su API abierta, tendrás la libertad de trabajar con las herramientas que mejor se adapten a tu negocio, maximizando el potencial de tu operación.
          </p>
        </div>
      </div>
    </div>
    {/* Imagen ahora ocupa 4 columnas en desktop */}
    <div className="grid gap-6 md:col-span-2 lg:col-span-3">
      <div>
        <img
          className="w-full h-auto"
          src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/Ecosistema.webp"
          alt="Promoción"
        />
      </div>
    </div>
  </div>
  <section class="px-4 py-12 mx-auto max-w-7xl">
  <div class="grid grid-cols-2 gap-10 text-center lg:grid-cols-9">
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/Invu-logo.png" class="block object-contain h-16" />
    </div>
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/Shipday-logo.png" class="block object-contain h-16" />
    </div>
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/Powertranz-logo.png" class="block object-contain h-16" />
    </div>
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/Picker-logo.png" class="block object-contain h-16" />
    </div>
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/Yappy-logo.png" class="block object-contain h-16" />
    </div>
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/Uber-direct-logo.png" class="block object-contain h-16" />
    </div>
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/Mastercard-logo.png" class="block object-contain h-16" />
    </div>
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/Visa-logo.png" class="block object-contain h-16" />
    </div>
    <div class="flex items-center justify-center">
      <img src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/logos/ASAP-logo.png" class="block object-contain h-16" />
    </div>
  </div>
</section>
    {/* Tarjetas informativas pequeñas */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12 text-center">
    <div className="bg-[#F8F8F8] rounded-2xl p-8">
      <h4 className="text-[#FE7066] font-bold mb-2 text-2xl break-words max-w-[220px] mx-auto">Integración con Delivery</h4>
      <p className="text-gray-600 text-lg mt-3">Conecta tu negocio con las principales plataformas de delivery y gestiona todos tus pedidos desde un solo lugar.</p>
    </div>
    <div className="bg-[#F8F8F8] rounded-2xl p-8">
      <h4 className="text-[#FE7066] font-bold mb-2 text-2xl break-words max-w-[220px] mx-auto">Integración con Sistemas POS</h4>
      <p className="text-gray-600 text-lg mt-3">Nuestro sistema se adapta perfectamente a cualquier sistema POS, asegurando una sincronización fluida de ventas, inventarios y reportes.</p>
    </div>
    <div className="bg-[#F8F8F8] rounded-2xl p-8">
      <h4 className="text-[#FE7066] font-bold mb-2 text-2xl break-words max-w-[250px] mx-auto">Flexibilidad Total con Nuestra API Abierta</h4>
      <p className="text-gray-600 text-lg mt-3">Nuestra API abierta permite una conexión rápida y personalizada con cualquier plataforma que tu negocio necesite..</p>
    </div>
    </div>
</div>


<section class="bg-white">
      <div class="max-w-7xl px-6 py-6 mx-auto">
        <main class="relative z-20 w-full mt-8 md:flex md:items-center xl:mt-12">
          <div class="absolute w-full bg-[#F1F1F1] -z-10 md:h-[450px] rounded-3xl"></div>
            <div class="w-full p-6 bg-[#F1F1F1] md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-evenly">
              <div class="mt-2 md:mx-6">
                <p class="text-4xl font-semibold  text-[#1d1d1f] md:text-5xl">Fideliza a tus clientes y recompénsalos en cada compra</p>
                <div className="mt-6">
                  <p className="font-light text-xl mt-3 tracking-normal leading-relaxed text-gray-700 max-w-2xl">
                  Con nuestro programa de fidelización, tus clientes acumulan puntos con cada compra, los cuales pueden canjear por descuentos, productos exclusivos y beneficios especiales.
                  </p>
                  <a
                    className="mt-6 inline-block rounded-full border border-[#FE7066] bg-[#FE7066] lg:px-12 px-8 py-3 text-sm md:text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden"
                  >
                    Empieza Ahora
                  </a>
                </div>
              </div>
              <img class="md:mx-6 lg:mt-0 mt-12 object-cover md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[26rem] md:rounded-2xl" src="https://img.invupos.com/clauweb/Integraciones%20sin%20limites/mockupcards.webp" alt="client photo" />
          </div>
        </main>
      </div>
    </section>

    </>
  )
}

export default Unlimited_integrations